import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/vanatu-team/vanatu-app-landing-page/src/layout/DefaultPageLayout.tsx";
import { MagicEmailLinkFallback } from '../../components/MagicEmailLinkFallback';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <MagicEmailLinkFallback {...props} mdxType="MagicEmailLinkFallback" />
    <p>{`Sorry - Something went wrong...`}</p>
    <ul>
      <li parentName="ul">{`Please make sure you are `}<strong parentName="li">{`opening the Magic Link from your mobile phone`}</strong>{` (the link won't work from desktop computer)`}</li>
      <li parentName="ul">{`If the Magic Link didn't work on your mobile phone: `}<strong parentName="li">{`Try resending the Magic link in the app`}</strong>{` and open it again`}</li>
    </ul>
    <p><strong parentName="p">{`Still doesn't help?`}</strong>{` Please contact our support via `}<a parentName="p" {...{
        "href": "mailto:support@vanatu.app"
      }}>{`support@vanatu.app`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      