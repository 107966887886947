import React, { useEffect } from 'react';
import { LayoutProps } from '../layout/DefaultPageLayout';
import { getOs } from '../service/DeviceInfoService';

export const MagicEmailLinkFallback = (props: LayoutProps) => {
  useEffect(() => {
    const os = getOs();
    if (os === 'android') {
      const current = new URL(window.location.href);
      const token = current.searchParams.get('token');

      if (token) {
        const host = window.location.host;
        window.location.replace(
          `link://${host}/app/magic-email-link?token=${token}`,
        );
      }
    }
  }, []);

  return <></>;
};
